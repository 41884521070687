import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from '@react-oauth/google';

// font icon
// import { FaFacebook } from "react-icons/fa";

// styles
import { WrapForm, WrapSocialLogin, Separator, Form } from './style'

// components 
import Input from '~/components/form/Input'
import Label from '~/components/form/Label'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'

// rest
import { usePost } from '~/services/rest'
import { useEffect } from 'react';

// conf
import { BASE_URL_SITE } from '~/services/conf'

// functions
import { createCookie } from '~/services/functions'

const FormLogin = ({ handleFormFill, handleLogin, formData, sending, resMsg }) => {

  // const [resLoginSocialFacebook, postLoginSocialFacebook] = usePost('sessions/social');
  const [resLoginSocialGoogle, postLoginSocialGoogle] = usePost('sessions/social/google');
  const [erroSocialLogin, setErroSocialLogin] = useState();

  // response login facebook -----
  // useEffect(() => {
  //   if (resLoginSocialFacebook.error) {
  //     setErroSocialLogin(resLoginSocialFacebook.error)
  //   }
  //   if (resLoginSocialFacebook.data.token) {
  //     // cria cookies para manter login entre plataformas
  //     createCookie('@jgwebcom:token', resLoginSocialFacebook.data.token)
  //     createCookie('@jgwebcom:user', JSON.stringify(resLoginSocialFacebook.data.usuarioAuth))
  //     window.location.reload()
  //   }
  // }, [resLoginSocialFacebook])

  // response login google -----
  useEffect(() => {
    if (resLoginSocialGoogle.error) {
      setErroSocialLogin(resLoginSocialGoogle.error)
    }
    if (resLoginSocialGoogle.data.token) {
      // cria cookies para manter login entre plataformas
      createCookie('@jgwebcom:token', resLoginSocialGoogle.data.token)
      createCookie('@jgwebcom:user', JSON.stringify(resLoginSocialGoogle.data.usuarioAuth))
      window.location.reload()
    }
  }, [resLoginSocialGoogle])

  // handle login facebook -----
  // const handleFacebook = (response) => {
  //   const data = {
  //     plataforma: "facebook",
  //     token: response
  //   }
  //   if (response) postLoginSocialFacebook(data)
  // }

  // handle login google -----
  const handleSignIn = (dataUser) => {
    if (dataUser) {
      const dataSend = {
        token: dataUser.credential,
        client_id: dataUser.clientId,
      }
      postLoginSocialGoogle(dataSend)
    }
  }

  return (
    <WrapForm>

      <h2>Acesse agora</h2>

      <WrapSocialLogin>
        {/* <FacebookLogin
          // appId="1041102636676068" //teste
          appId="153296446699601"
          version="18.0"
          fields="name,email,picture"
          scope='public_profile,email'
          callback={handleFacebook}
          render={renderProps => (
            <Button className="facebook-button" onClick={renderProps.onClick} width="100%"><FaFacebook size={25} /> Continuar com o Facebook</Button>
          )}
        /> */}
        <GoogleLogin
          onSuccess={credentialResponse => handleSignIn(credentialResponse)}
          onError={() => handleSignIn()}
          width={300}
          shape='pill'
          logo_alignment='center'
        />
      </WrapSocialLogin>

      <Separator>
        <span>ou</span>
      </Separator>

      <Form sending={sending}>
        <Label htmlFor="fm_email">E-mail:</Label>
        <Input type="text" id="fm_email" onChange={handleFormFill('email')} value={formData.email} />
        <Label htmlFor="fm_pass">Senha:</Label>
        <Input type="password" id="fm_pass" onChange={handleFormFill('senha')} value={formData.senha} />
        <Link to="/login/recuperar-senha">Esqueci minha senha</Link>
        <div className="text-right">
          <Button type="button" onClick={() => handleLogin()}>Acessar</Button>
        </div>
        {
          resMsg && <MsgResponse msg={resMsg} width='100%' theme='error' />
        }
        {
          erroSocialLogin && <MsgResponse msg={erroSocialLogin} width='100%' theme='error' />
        }
      </Form>

      <div className="text-center">
        <a href={BASE_URL_SITE + 'cadastro'} title="Ainda não tem cadastro? clique aqui">Ainda não tem cadastro? <span>clique aqui</span></a>
      </div>

    </WrapForm>
  )
}

export default FormLogin