import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// elements
import LeftSidebar from '~/elements/LeftSideBar'
import RightSidebar from '~/elements/RightSideBar'
import AlertRemoteUser from '~/components/AlertRemoteUser'

// functions
import { createCookie } from '~/services/functions'

// components
import LightboxAlert from '~/components/LightboxAlert'
import Button from '~/components/form/Button'
import { color } from '~/styles/variables'

const TemplateHome = ({ children }, props) => {

  const history = useHistory()
  const auth = useContext(AuthContext);

  // config lightbox alert -------------
  const [lightboxAlert, setLightboxAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState();
  const [msgAlert, setMsgAlert] = useState();
  const [buttonsAlert, setButtonsAlert] = useState();
  const [closable, setClosable] = useState(false);

  useEffect(() => {
    if (auth.user) {

      const themeButton = {
        blue: {
          bg: color.primary,
          fc: '#FFFFFF',
          bdc: color.primary
        },
      }

      const handlePrimeiroAcesso = (pageRedirect = false) => {
        setLightboxAlert(false);
        auth.user.primeiro_acesso = 0;
        createCookie('@jgwebcom:user', JSON.stringify(auth.user))

        if (pageRedirect) {
          history.push('/perfil')
        }
      }

      const handleAvisoDataNascimento = (pageRedirect = false) => {
        setLightboxAlert(false);

        if (pageRedirect) {
          history.push('/perfil')
        }
      }

      if (auth.user.primeiro_acesso === 1) {
        setTitleAlert('<h2 class="tts-alert">Aviso</h2>')
        setMsgAlert(`<p>Olá, estamos felizes com seu primeiro acesso. Aproveite para conferir se você registrou o seu nome corretamente. Após concluir o curso, o certificado será emitido para este nome e não poderá ser alterado.</p><p><strong>${auth.user.nome}</strong></p>`)
        setButtonsAlert([<Button key={1} onClick={() => handlePrimeiroAcesso('perfil')}>Quero alterar</Button>,
        <Button key={2} onClick={() => handlePrimeiroAcesso()} theme={themeButton.blue}>Está correto</Button>])
        setLightboxAlert(true)
      } else if (!auth.user.data_nascimento && history.location.pathname !== '/perfil') {
        setTitleAlert('<h2 class="tts-alert">Aviso</h2>')
        setMsgAlert(`<p>Atualize seu cadastrado informando sua data de nascimento.</p>`)
        setClosable(true);
        setButtonsAlert([<Button key={1} onClick={() => handleAvisoDataNascimento('perfil')}>Atualizar cadastro</Button>,
        <Button key={2} onClick={() => handleAvisoDataNascimento()} theme={themeButton.blue}>Depois</Button>])
        setLightboxAlert(true)
      }
    }
  }, [auth.user, history])
  // config lightbox alert -------------

  return (
    <div className="full-container">
      <LeftSidebar />
      {auth.user.remoto && <AlertRemoteUser />}
      <div className="main-content-offset">
        <div className="main-content">
          {children}
        </div>
      </div>
      <RightSidebar />
      {lightboxAlert && <LightboxAlert setLightboxAlert={setLightboxAlert} title={titleAlert} msg={msgAlert} closable={closable} buttonsAlert={buttonsAlert} />}
    </div>
  )
}
export default TemplateHome