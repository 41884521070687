// LOCALHOST
// export const BASE_URL_SITE = 'http://localhost/jgwebcom/ambiente-virtual-de-aprendizagem/site/'
// export const BASE_URL_API = 'http://localhost:3333/' // URL API
// export const COOKIE_DOMAIN = ''

// PRODUCTION
export const BASE_URL_SITE = 'https://ava.jgwebcom.com/'
export const BASE_URL_API = 'https://ava-api.jgwebcom.com/' // URL API
export const COOKIE_DOMAIN = '.jgwebcom.com'

// BOTHS
export const MINIMUM_VIDEO_PLAYED = 80 // percentage
export const BLOCK_BY_VIDEO_PAYED = true // ativa o bloqueio de aula por tempo assistido